import Saurav from "../../assets/img/teams/saurav.jpeg"
import Siva from '../../assets/img/works/siva.webp'
import Shilpa from "../../assets/img/works/shilpa.jpeg";
import Moya from "../../assets/img/works/moya.jpeg";
import Mudit from "../../assets/img/works/mudit.jpeg";
import Ceola from "../../assets/img/works/ceola.jpeg";
import Tony from "../../assets/img/works/tony.jpeg";

export const testimonialData = [
  {
    name: "Tony O. Elumelu",
    designation: "Chairman at Heirs Holdings",
    image: Tony,
    description:
      "My experience working with Fusionlite team for one of my Node JS applications was great, especially with Amir for being available all the time to exchange requirements in between development phases.",
    bgColour: "bg-secondary",
    bColor: "border-secondary",
  },
  {
    name: "Ceola Halloran",
    designation: "Greater Chicago",
    image: Ceola,
    description:
      "I connected Fusionlite agency for my application's UX design through Upwork, They deliver quality work under deadline. My other design products are currently being worked on by them",
    bgColour: "bg-lightGreen",
    bColor: "border-lightGreen",
  },
  {
    name: "Moya Richards ",
    designation: "Skokie, Illinois, United States",
    image: Moya,
    description:
      "They did a great job delivering all the requirements required. They are highly responsive and skilled in what they are doing, I will definitely work with them again.",
    bgColour: "bg-lightBlue",
    bColor: "border-lightBlue",
  },
  {
    name: "Shiva Sai",
    designation: "Banglore, India",
    image: Siva,
    description:
      "Fusionlite has high professional and ethical standards,in depth knowledge and expertise, in react and react native code. Very agile and super cool approach to problem solving with great interaction. I will definitely work with them for all my future endeavours and will definitely recommend them to my other contacts also. Team who met the deadline with great accuracy and was able to fix the bugs promptly.",
    bgColour: "bg-secondary",
    bColor: "border-secondary",
  },
  {
    name: "Somay Jain",
    designation: "CTO at Chordia",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrFlnVg35D0RiaKH0eCXDuvtZ3DXZDvB-GkA&usqp=CAU",
    description:
      "It has been a pleasure working with Amir and his team for the past two years. They are very minded individuals. Every problem has a solution with them. Fusionlite is also on my radar for the future.",
    bgColour: "bg-secondary",
    bColor: "border-secondary",
  },
  {
    name: "Shilpa Dubey",
    designation: "Paris, France",
    image: Shilpa,
    description:
      "Fusionlite team members are extremely energetic. Their highly skilled mind and power can impress anyone. Their new ideas helped them grow in career. Best wishes for a bright future for them.",
    bgColour: "bg-lightGreen",
    bColor: "border-lightGreen",
  },
];