import React from "react";
import AboutComponent from "../componets/about-us";
import BannerComponent from "../componets/banner";
import OurClientComponent from "../componets/clients";
import ContactUsComponent from "../componets/contact";
import FooterComponent from "../componets/footer";
import NavbarComponent from "../componets/navbar";
import OurWorkComponent from "../componets/our-works";
import OurServicesomponent from "../componets/services";
import TeamsComponent from "../componets/teams";
import PeopleComponent from "../componets/people";

const HomeComponent = () => {
  return (
    <>
      <NavbarComponent />
      <BannerComponent />
      <OurServicesomponent />
      <AboutComponent />
      <OurWorkComponent />
      <PeopleComponent />
      <TeamsComponent />
      <ContactUsComponent />
      <OurClientComponent />
      <FooterComponent />
    </>
  );
};

export default HomeComponent;
