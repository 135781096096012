import React from "react";
import { Link } from "react-scroll";
import LogoIcon from "../../assets/img/home/logo.svg";
import MobileNavBar from "./mobile-nav";
// import Hamburger from "../../assets/img/home/icons8-menu-128.png"

export   const menus = ["home","services", "about", "work","teams", "client"];
const NavbarComponent = () => {
  return (
    <>
      <navbar className="absolute flex justify-between pl-4 xl:pl-24 xl:pr-10 w-full xl:w-3/5 mt-6 items-center z-10">
        <div className="logo">
          {/* <img src={LogoIcon} className="lg:w-60" /> */}
          <h3 className="text-40 text-secondary logo">Fusionlite</h3>
        </div>
        <ul className="hidden xl:flex justify-between flex w-[60%]">
          {menus.map((menu) => {
            return (
              <li>
                <Link
                  activeClass="active"
                  to={menu}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="text-white text-xs cursor-pointer uppercase font-['Montserrat'] font-semibold"
                >
                  {menu}
                </Link>
              </li>
            );
          })}
        </ul>
      </navbar>
      <div className="lg:hidden">
        <MobileNavBar />
      </div>
    </>
  );
};

export default NavbarComponent;
