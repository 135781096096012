import React from "react";
import { Link } from "react-scroll";
import Email from "../../assets/img/about/email.svg";
import Banner from "../../assets/img/about/banner.jpg";
import "./index.css";

const AboutComponent = () => {
  return (
    <section
      className="pl-8 pr-8  lg:pl-24 lg:pr-24 bg-lightBlue  pt-24 overpad about relative"
      id="about"
    >
      <div className="grid grid-cols-5 grid-gap  mt-[-175px] md:mt-0">
        <div className="relative col-span-5 lg:col-span-2">
          <div className="bg-white p-3 " data-aos="fade-right">
            <img src={Banner} />
          </div>
        </div>

        <div className="relative col-span-5 lg:col-span-3 lg:pl-36">
          <div className="" data-aos="fade-up">
            <h2 class="text-heading whitespace-nowrap mb-4 mt-8 lg:mt-0 lg:mb-0">
              <span className="outline-font">ABOUT</span>{" "}
              <span className="text-primary">US</span>
            </h2>
            <h3
              data-aos="fade-up"
              data-aos-duration="2000"
              className="upparcase text-primary font-['Nunito'] lg:w-[29rem] padd font-bold  mb-3"
            >
              STEP INTO THE INSPIRATION FOR A NEW WEB
            </h3>
            <p
              data-aos="fade-up"
              data-aos-duration="3000"
              className=" mb-3 lg:pr-16"
            >
              Fusionlite started out as a software development company. Providing
              solutions to global organizations and technology start-ups.
              Developing more intelligent software solutions has helped
              companies and established brands reinvent their business through
              digitalization. To bring your dream to life, our dedicated team
              members blend a variety of ingredients.
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="4000"
              className=" lg:pr-16 mb-3"
            >
              It is the logical minds that know how to understand the
              technicalities of the business of our clients, the methodical
              designers and project managers, the driven managers who pay close
              attention to quality and delivery, as well as the visionaries who
              transform the way we view things.
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="4500"
              className=" lg:pr-16"
            >
              As we learn the latest trends, adapt to the new solutions, and use
              the latest technology available, we ensure that it is perfect for
              you today, and in the future. To put it simply, we are always
              up-to-date on everything you need to succeed.
            </p>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <button
                data-aos="flip-up"
                data-aos-duration="5000"
                className="bg-primary bttn  rounded-sm mt-6 font-bold text-white"
              >
                <img src={Email} className="inline-block w-6 mr-2" /> SHOOT AN
                EMAIL
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
