import React from "react";
import "./index.css";
import BigQuot from "../../assets/img/people/big-quotation.svg";
import TestimonialCard from "./card";
import { testimonialData } from "./card-data";
import Reply from "../../assets/img/people/reply.svg";

const PeopleComponent = () => {
  const col1 = [],
    col2 = [];
  for (let i = 0; i < testimonialData.length; i++) {
    i % 2 === 0 ? col1.push(testimonialData[i]) : col2.push(testimonialData[i]);
  }


  return (
    <section className="overflow-hidden">
      <div class="triagle -mt-8"></div>
      <div className="bg-primary relative px-4 pt-4 pb-10  lg:px-24 lg:pb-24">
        <div
          className=" flex justify-center pt-[-55px] lg:pt-0 md:justify-between"
          data-aos="fade-down"
        >
          <div className="grid grid-cols-2">
            <div className="relative col-span-2 md:hidden">
              <img src={BigQuot} className="w-[61px] ml-[46px] mb-7" />
            </div>
            <div className="relative col-span-2">
              <span className="text-heading whitespace-nowrap outline-font-white">
                Real People
              </span>
            </div>
            <div className="relative col-span-2 ">
              <span className="text-heading whitespace-nowrap text-gold mt-4">
                Real Talks
              </span>
            </div>
          </div>
          <img src={BigQuot} className="hidden md:flex md:w-25 xl:w-30" />
        </div>
        <div className="text-white font-['Nunito'] textSize my-4 md:my-12  md:w-[60%]">
          Hear from the ones who have experienced Fusionliters creating wonders
          with their business. You could be one of them too.
        </div>
        <div className="grid grid-cols-2 mt-6 md:mt-12">
          <div className="col-span-2 md:col-span-1 ">
            {col1.map((testimonial) => (
              <div className="md:mr-6 mb-12">
                <TestimonialCard data={testimonial} />
              </div>
            ))}
          </div>
          <div className="col-span-2 md:col-span-1">
            {col2.map((testimonial) => (
              <div className="md:ml-6 mb-12">
                <TestimonialCard data={testimonial} />
              </div>
            ))}
            <div className="mt-20 flex justify-end">
              <img className="-mt-6 w-20" src={Reply} />
              <a
                href="https://www.upwork.com/freelancers/~011b60a0da35fb7ae2?viewMode=1"
                target="_blank"
                data-aos="flip-up"
                className="bg-secondary pad   text-[11px] md:text-[15px] rounded-sm mt-6 font-bold text-primary ml-4"
              >
                VIEW ALL
              </a>
            </div>
          </div>
        </div>
        {/* <div className="flex">
        <TestimonialCard /> 
        <TestimonialCard />
        </div> */}
      </div>
    </section>
  );
};

export default PeopleComponent;
