import DesignIcon from "../../assets/img/about/design.svg";
import WebAppIcon from "../../assets/img/about/web-app.svg";
import MobileAppIcon from "../../assets/img/about/mobile-app.svg";
import CustomAppIcon from "../../assets/img/about/custom-app.svg";

export const servicesData = [
  {
    icon: DesignIcon,
    title: "Creative Product Design",
    desc: "Staying aligned with brand needs accuracy, Fusionlite applies agile methods and helps develop products suitable to your business needs. While ideating, our central focus remains on the character of the brand or their business and their audience.  Creating a user friendly experience, we aim todeliver brand story the best. Simultaneously, we make sure all the information are easily accessible while establishing one-to-one connection with your audience.",
    delay: 3000,
  },
  {
    icon: WebAppIcon,
    title: "Web App Development",
    desc: "Not every business demands similar solutions when it comes to Web App Development, Hence, choosing the right kind for your business is so crucial. We create solutions that are ready to serve your audience and aligned with your business visions. Whatever your goals are, we treat them like our own and aim to fulfil them all. Fusionlite assures your business stands out and you get noticed.",
    delay: 4500,
  },
  {
    icon: MobileAppIcon,
    title: "Mobile App Development",
    desc: "Our motto is to help businesses, enterprises and people with the best mobile app development service. Enables seamless user experience across all digital spaces and smart devices is our goal. Bringing convenience to your finger tips is what we’re good at. Starting from conceptualisation to delivery, assistance to maintenance - we do it all.",
    delay: 6500,
  },
  {
    icon: CustomAppIcon,
    title: "Custom App Development",
    desc: "Customised apps are accurately aligned with your business and security needs. It adapts to your business environment and delivers the desired result within the stipulated timeframe. With custom treatment, we make sure to highlight all unique aspects of your business. Simultaneously, this service offers you to have a flexible budget too.",
    delay: 8500,
  },
];
