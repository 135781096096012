import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { servicesData } from "./services-data";
import "./index.css";


const OurServicesComponent = () => {

  return (
    <section className="pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20" id="services">
      <h2 class="text-heading whitespace-nowrap">
        <span className="outline-font">OUR</span>{" "}
        <span className="text-primary">SERVICES</span>
      </h2>
      <p className="lg:w-3/4">
        We always look forward to work with clients who let us experiment to
        their best potential. We believe in welcoming futuristic thoughts, new
        ideas & non-stop innovation to the table. No matter what your business
        demands, our expertise is gathering the best resources to offer on-time
        solutions.
      </p>

      <div className="columns-1 md:columns-2 xl:columns-4 md:mt-8 lg:grid lg:grid-cols-4 lg:gap-4">
        {servicesData.map((service) => {
          return (
            <div
              className=" md:px-4 py-4 "
              data-aos="fade-left"
              data-aos-duration={service.delay}
            >
              <div className="flex md:block">
                <img className="w-10 md:w-auto" src={service.icon} />
                <h5 className="text-primary mt-5 font-['Montserrat'] font-bold text-[1.15rem] md:text-2xl h_mar mb-3">
                  {service.title}
                </h5>
              </div>

              <p className="pl-12 md:pl-0">
                <ReactReadMoreReadLess
                  charLimit={180}
                  readMoreText={"Read more"}
                  readLessText={"Read less"}
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  {service.desc}
                </ReactReadMoreReadLess>
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurServicesComponent;
