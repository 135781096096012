import React, { useEffect, useState } from "react";
import Aos from "aos";
import { Toaster } from "react-hot-toast";
import "aos/dist/aos.css";
import HomeComponent from "./views/home";
import Rocket from "../src/assets/img/rocket.png";
import "./app.css";

function App() {
  const [isEnable, setEnable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (scrollPosition > 400) {
      setEnable(true);
    } else {
      setEnable(false);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <div className="App">
      <HomeComponent />
      {isEnable && (
        <button
          className="bg-secondary fixed bottom-[25px] right-[10px] rounded-md  p-1 z-10"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <img src={Rocket} className="w-10" />
        </button>
      )}

      <Toaster position="top-right" />
    </div>
  );
}

export default App;
