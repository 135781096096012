import React, { useState } from "react";
import TeamCardComponent from "./card";
import { coreTeam, DevTeam } from "./card-data";
import BrnadIcon from "../../assets/img/home/brand_icon.svg";
import "./index.css";
import MobileCardComponent from "./mobile-card";
import Slider from "react-slick";
import ModalComponent from "./modal";

const TeamsComponent = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    cssEase: "linear",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isShow, setShow] = useState(false);
  const [modalData, updateModalData] = useState("");
  // console.log("data from teams",modalData);
  return (
    <section className="pt-4 md:pt-12 overflow-hidden" id="teams">
      <div className="text-center">
        <h2 class="text-heading  whitespace-nowrap">
          <span className="outline-font">OUR </span>{" "}
          <span className="text-primary">TEAMS</span>
        </h2>
        <p className="m-auto relative px-2 mt-3 md:mt-0 md:px-0 md:w-[45%] z-10">
           A group of genius and erudite minds who breathe and talk code and solely
          believe in the power of delivering quality solutions for collective
          success
        </p>
      </div>
      <div className="bg-primary mt-5 relative pb-8 team_layer">
        <img
          src={BrnadIcon}
          className="absolute right-[50px] z-10 md:right-[190px] top-[80px] lg:top-[50px] opacity-25 w-10 md:w-14"
        />
        <div className="pa pb-0 lg:pb-20 pt-36 relative z-10">
          {/* Core Team  Desktop*/}
          <div className="mb-8 hidden md:block">
            <h4 className="font-['Lato'] font-semibold text-white text-2xl mb-5">
              Core Team
            </h4>
            <div className="grid grid-cols-4 gap-4 h-36">
              {coreTeam.map((member) => {
                return <TeamCardComponent data={member} />;
              })}
            </div>
          </div>

          {/* Core Team Mobile*/}
          <div className="mb-8 lg:hidden block">
            <h4 className="font-['Lato'] text-[15px] font-semibold text-white mb-5">
              Core Team
            </h4>
            <div className="">
              <Slider {...settings}>
                {coreTeam.map((member) => {
                  return (
                    <MobileCardComponent
                      data={member}
                      updateModalData={updateModalData}
                      setShow={setShow}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>

          {/* Development Team Desktop*/}
          <div className="hidden md:block">
            <h4 className="font-['Lato'] font-semibold text-white text-2xl	 mb-5">
              Development Team
            </h4>
            <div className="grid grid-cols-4 gap-4 h-36	">
              {DevTeam.map((member) => {
                return <TeamCardComponent data={member} />;
              })}
            </div>
          </div>

          {/* Development Team Mobile*/}
          <div className="lg:mb-8 lg:hidden block">
            <h4 className="font-['Lato'] font-semibold text-white text-[15px] mb-5">
              Development Team
            </h4>
            <div className="">
              <Slider {...settings}>
                {DevTeam.map((member) => {
                  return (
                    <MobileCardComponent
                      data={member}
                      updateModalData={updateModalData}
                      setShow={setShow}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent modalData={modalData} isShow={isShow} setShow={setShow} />
    </section>
  );
};

export default TeamsComponent;
