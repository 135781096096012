import React from "react";
import ArrowIcon from "../../assets/img/teams/arrow.svg";

const TeamCardComponent = ({ data }) => {
  return (
    <div className="relative" data-aos="zoom-in">
      <div className="card">
        <div className="bg-white rounded-t-md shadow-md front">
          <div className="mx-auto flex justify-between p-6">
            <div>
              <div className="font-bold text-primary font-['Lato'] text-2xl mb-3">
                {data.name}
              </div>
              <p className="text-slate-900 text-sm	">{data.position}</p>
            </div>
            <div className="shrink-0">
              <img
                className="h-12 w-12 border-2 border-primary rounded-full"
                src={data.img}
              />
            </div>
          </div>
          <div className="bg-lightGreen p-4 rounded-b-md text-right uppercase font-bold text-sm">
            Read Bio <img src={ArrowIcon} className="inline-block" />
          </div>
        </div>
        <div className="back bg-white rounded-md p-4">
          <p className="text-[13px] lg:text-[13px] md:text-[12px]	leading-[23px]">
            {data.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeamCardComponent;
