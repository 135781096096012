import React, { useState } from "react";
import { Link } from "react-scroll";
import Icon from "./icon";
import { menus } from "./index";
import './index.css'
const MobileNavBar = () => {
    const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div className="mobile-hamburger">
        <button
          onClick={() => setOpen(!isOpen)}
          type="button"
          aria-label="toggle navigation"
          className={`${
            isOpen ? "nav-open" : ""
          } absolute right-0 top-[3px] z-50	text-white nav-toggle`}
        >
          <span className="nav-svg">
            <span className="dots"></span>
            <Icon />
          </span>
        </button>
      </div>

      <ul
        class={`${
          isOpen ? "" : "scale-0"
        } fixed bg-primary w-full h-full left-0 right-0 top-0 bottom-0 z-10 flex p-20 flex-col items-center ease-in duration-300 `}
      >
        {menus.map((item) => {
          return (
            <li class="flex-1">
              <Link
                onClick={() => setOpen(!isOpen)}
                activeClass="active"
                to={item}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="text-white uppercase font-semibold text-[14px] underline"
              >
                {item}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default MobileNavBar;
