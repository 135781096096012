import React from "react";
import SavelTetech from "../../assets/img/clients/seveltetech.svg";
import QuickWorkz from "../../assets/img/clients/quickworkz.svg";
import DataPlant from "../../assets/img/clients/dataplant.png";
import Quality from "../../assets/img/clients/quality.svg";
import PTD from "../../assets/img/clients/ptd.png";
import './index.css'

import Slider from "react-slick";

const OurClientComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section className="md:pt-6 pb-0 md:pb-8 px-[1rem] md:px-0" id="client">
      <div className="text-center mb-[2.5rem]">
        <h2 className="text-heading">
          <span className="outline-font">OUR </span>{" "}
          <span className="text-primary">CLIENTS</span>
        </h2>
        <p className="m-auto relative lg:w-1/2 z-10">
          Without their support, our business wouldn’t flourish. Without their
          encouragement, we couldn’t realise our potential. Without their
          feedback, we wouldn’t grow this much.
        </p>
      </div>
      <div className="wd m-auto">
        <Slider {...settings}>
          <div className="rounded-md	p-2	bg-primary flex w-40 pt-9 	h-36	border-8 shadow-lg	border-secondary">
            <img src={SavelTetech} className="inline-block m-auto" />
          </div>
          <div className="rounded-md	p-2	bg-primary shadow-lg flex w-40	h-36	border-8	border-secondary	">
            <img src={QuickWorkz} className="inline-block m-auto" />
          </div>
          <div className="rounded-md	p-2	bg-primary shadow-lg flex w-40	h-36	border-8	border-secondary	">
            <img src={DataPlant} className="inline-block m-auto h-[100px]" />
          </div>
          <div className="rounded-md	p-2	flex w-40	h-36 shadow-lg	border-8	border-secondary">
            <img src={''} className="inline-block m-auto" />
          </div>
          <div className="rounded-md shadow-lg	p-2	bg-primary w-40	h-36 border-8	border-secondary	flex">
            <img src={PTD} className="inline-block m-auto" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default OurClientComponent;