import React from "react";
import { Link } from "react-scroll";
import LogoIcon from "../../assets/img/home/logo.svg";
import AddressIcon from "../../assets/img/footer/address.svg";
import EmailIcon from "../../assets/img/footer/email.svg";
import CallIcon from "../../assets/img/footer/call.svg";
import { footerInfo } from "./footer-data";
import "./index.css";

const FooterComponent = () => {
  const menus = ["home", "about", "work", "client", "teams", "services"];
  const ourServices = [
    "Creative Product Design",
    " Web App Development",
    " Mobile App Development",
    "Custom App Development",
  ];

  return (
    <footer className="pt-9 bg-primary relative overflow-hidden">
      <div className="white-border relative z-9">
        <div className="pl-4 pr-4 md:pl-24	md:pr-24 pt-9">
          {/* Footer section heading start here */}
          <h2 class="text-heading ">
            <span className="footer_heading_font">CONTACT</span>{" "}
            <span className="text-white">US</span>
          </h2>
          {/* Footer menus section start here */}
          <div className="grid grid-cols-4 lg:grid-cols-3 gap-4 mt-7 md:w-3/5 ">
            <div className="col-span-4 lg:col-span-1 ">
              {/* <img src={LogoIcon} className="w-44 mb-7" /> */}
               <h3 className="mb-2 text-2xl text-white">Fusionlite</h3>
              <h5 className="text-white mb-3">
                <img src={AddressIcon} className="inline-block" /> Address
              </h5>
              <p className="text-white mb-3">{footerInfo.address}</p>
              <hr className="mt-4 mb-4 border-t border-t-borderColor" />
              <p className="text-white mb-1">
                <img src={EmailIcon} className="inline-block mr-2" />{" "}
                <a href={`mailto:${footerInfo.email}`}>{footerInfo.email}</a>
              </p>
              <p className="text-white mb-3">
                <img src={CallIcon} className="inline-block mr-2" />{" "}
                <a href={`tel:${footerInfo.mob}`}>{footerInfo.mob}</a>
              </p>
            </div>
            <div className="col-span-1 lg:col-span-1 lg:pl-16">
              <h4 className="text-white  text-[14px] md:text-[16px] uppercase font-['Lato'] font-bold mb-5 whitespace-nowrap">
                QUICK LINKS
              </h4>
              <ul className="grid gap-y-0 md:gap-y-2">
                {menus.map((item) => {
                  return (
                    <li>
                      <Link
                        activeClass="active"
                        to={item}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        className="text-white  text-[10px] md:text-[12px]  uppercase font-['Montserrat'] font-semibold cursor-pointer"
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-span-3 lg:col-span-1 pl-12 lg:pl-5">
              <h4 className="text-white text-[14px] md:text-[16px] text uppercase font-['Lato'] font-bold mb-5">
                OUR SERVICES
              </h4>
              <ul className="grid gap-y-2">
                {ourServices.map((item) => {
                  return (
                    <li>
                      <Link
                        activeClass="active"
                        to="services"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        className="text-white text-[10px] md:text-[12px] uppercase font-['Montserrat'] font-semibold cursor-pointer"
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Copyright area start here */}
          <div className="pt-8 pb-8 mt-3 border-t border-t-borderColor">
            <p className="text-white">©2023 Fusionlite</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
