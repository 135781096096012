import React from "react";
import Cofyview from "../../assets/img/our-work/cofyview.png";
import Teamile from '../../assets/img/our-work/teamile.png'
import GitState from "../../assets/img/our-work/gitstats.png";
import Ecommerce from "../../assets/img/our-work/ecommerce.png";
import Spa from "../../assets/img/our-work/spa.png";
import "./index.css";

const OurWorkComponent = () => {
  return (
    <div
      className="flex flex-col items-center px-4 lg:px-24 pt-4 lg:pt-10"
      id="work"
    >
      <h2 class="text-heading whitespace-nowrap">
        <span className="outline-font">RECENT</span>{" "}
        <span className="text-primary">WORKS</span>
      </h2>
      <div className="  font-['Nunito'] textSize sm:w-[54%] text-left md:text-center  mt-2 lg:mt-0">
        Delivered on time with 100% expertise, perfection, precision & most
        importantly, just the way they like.
      </div>

      <div class="grid-container grid grid-cols-5 w-full mt-8 gap-4 h-[1000px]">
        <div class="col-span-5 md:col-span-2">
          <img
            src={Cofyview}
            class="lg:h-1/3 mb-4 border-4"
            data-aos="zoom-in"
          />
          <img
            src={Teamile}
            class="lg:h-1/3 mb-4 border-4"
            data-aos="zoom-in"
          />
          <img
            src={GitState}
            class="lg:h-1/3 mb-4 border-4"
            data-aos="zoom-in"
          />
        </div>
        <div class="col-span-5 md:col-span-3 mt-7 lg:mt-0">
          <img
            src={Ecommerce}
            class="border-4 lg:h-3/4 mb-4"
            data-aos="zoom-in"
          />
          <img src={Spa} class="border-4 lg:h-1/4" data-aos="zoom-in" />
        </div>
      </div>
    </div>
  );
};

export default OurWorkComponent;
