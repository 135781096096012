import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";
import replyIcon from "../../assets/img/contact/reply.svg";
import { FaTimesCircle } from "react-icons/fa";
import EnvelopeIcon from "../../assets/img/contact/envelope.svg";
import "./index.css";

const ContactUsComponent = () => {
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
      phone: "",
    },
  });

  const onSubmit = async (data, e) => {
    try {
      setLoading(true);
      const { data: res } = await axios.post(
        "https://stenbuzz-api-9kve.vercel.app/mail",
        data
      );
      if (res.status) {
        reset();
        setLoading(false);
        toast.success("Your message has sent to us");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <section
      className="px-4 md:pl-24 pb-20	md:pr-24 pt-5 md:pt-10 bg-lightBlue contact relative overflow-hidden"
      id="contact"
    >
      <div className="grid grid-cols-5 gap-8 mt-2">
        <div className="hidden lg:block relative col-span-2 lg:pt-[27px]">
          <div>
            <img src={EnvelopeIcon} className=" 	" />
          </div>
        </div>
        <div className="hidden lg:block relative col-span-3">
          <div className="relative">
            <div className="">
              <h2 class="text-heading whitespace-nowrap" data-aos="fade-up">
                <span className="outline-font">CONTACT</span> <span> </span>
                <span className="text-primary">US</span>
              </h2>
              <p className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                If your favourite game is to code and speak software language
                fluently, then we are your type. Join the team of Fusionliters if
                you share the same coding frenzy.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:hidden  relative col-span-2">
          <div className="relative">
            <div className="">
              <h2 class="text-heading whitespace-nowrap" data-aos="fade-up">
                <span className="outline-font">CONTACT</span>{" "}
                <span className="text-primary">US</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="lg:hidden  relative col-span-3  ml-[81px]">
          <div>
            <img
              src={EnvelopeIcon}
              className=" w-16  xs:w-[45%] ml-10px md:ml-[100px]	"
            />
          </div>
        </div>
      </div>
      <p
        className="mt-4 m-auto lg:hidden "
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        If your favourite game is to code and speak software language fluently,
        then we are your type. Join the team of Fusionliters if you share the
        same coding frenzy.
      </p>
      <div className="grid  lg:grid-cols-3 sm:grid-cols-2 gap-8">
        <div className="relative  col-span-1"></div>
        <div className="relative col-span-2  lg:pl-[5.55rem]">
          <form
            data-aos="fade-left"
            data-aos-duration="4000"
            className="mt-4 lg:mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="name*"
                    className={`${
                      errors.name ? "border-rose-500" : "border-primary"
                    } h-12  p-5 focus-visible:outline-none border-b-2 w-full`}
                    {...register("name", { required: true })}
                  />
                  <FaTimesCircle
                    className={`${
                      !errors.name ? "hidden" : ""
                    } absolute right-[9px] top-[14px] text-rose-500`}
                  />
                </div>
                <div className="relative ">
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className={`${
                      errors.email ? "border-rose-500" : "border-primary"
                    } h-12  p-5 focus-visible:outline-none border-b-2 w-full`}
                    {...register("email", {
                      required: true,
                      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                  />
                  <FaTimesCircle
                    className={`${
                      !errors.email ? "hidden" : ""
                    } absolute right-[9px] top-[14px] text-rose-500`}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Phone Number*"
                    className={`${
                      errors.phone ? "border-rose-500" : "border-primary"
                    } h-12  p-5 focus-visible:outline-none border-b-2 w-full`}
                    {...register("phone", {
                      required: true,
                      pattern: /(7|8|9|6)\d{9}/,
                      maxLength: 10,
                    })}
                  />
                  <FaTimesCircle
                    className={`${
                      !errors.phone ? "hidden" : ""
                    } absolute right-[9px] top-[14px] text-rose-500`}
                  />
                </div>

                <div className="relative">
                  <input
                    type="text"
                    placeholder="Subject"
                    className={`${
                      errors.phone ? "border-rose-500" : "border-primary"
                    } h-12  p-5 focus-visible:outline-none border-b-2 w-full`}
                    {...register("subject", { required: true })}
                  />
                  <FaTimesCircle
                    className={`${
                      !errors.subject ? "hidden" : ""
                    } absolute right-[9px] top-[14px] text-rose-500`}
                  />
                </div>
              </div>
              <div className="relative">
                <textarea
                  placeholder="Message body"
                  className={`${
                    errors.message ? "border-rose-500" : "border-primary"
                  } w-full  p-5 focus-visible:outline-none border-b-2 resize-none`}
                  {...register("message", { required: true })}
                />
                <FaTimesCircle
                  className={`${
                    !errors.message ? "hidden" : ""
                  } absolute right-[9px] top-[14px] text-rose-500`}
                />
              </div>
            </div>
            <div className="text-right relative">
              <img
                src={replyIcon}
                className="inline-block absolute right-[30px] w-8 top-[10px]"
              />
              <button
                data-aos="flip-up"
                disabled={isLoading}
                type="submit"
                className={`px-2 pt-2 pb-1 md:px-7 md:py-3 text-[11px] md:text-[15px] rounded font-bold text-white mt-20 bg-primary`}
              >
                {isLoading ? "SENDING EMAIL" : "SUBMIT NOW"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUsComponent;
