import React from "react";

export default function ModalComponent({
  modalData,
  modalName,
  isShow,
  setShow,
}) {
  return (
    <div
      className={`${
        isShow ? "show modal-overlay" : "hide"
      } fixed bottom-0 left-0 right-0 z-50  w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full lg:hidden`}
    >
      <div class="relative w-full z-10 h-full max-w-2xl md:h-auto">
        <div class="relative bg-white rounded-t-3xl	 shadow dark:bg-gray-700">
          <div class="flex items-start justify-between pl-4 pr-2 pb-2 pt-7  rounded-t ">
            {/* Terms of Service */}
            <div className="text-center w-full">
              <img
                class="h-20 w-20 border-2 m-auto border-primary rounded-full mb-3"
                src={modalData.img}
              />
              <h3 class="text-xl font-semibold text-gray-90">
                {modalData.name}
              </h3>
              <p>{modalData.position}</p>
            </div>
            <button
              type="button"
              class=" hover:bg-gray-200 hover:text-gray-900 rounded-t text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white absolute right-[20px] top-[20px]"
              data-modal-toggle="defaultModal"
              onClick={() => setShow(false)}
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <div class="p-4 space-y-6">
            <p class="text-[15px] leading-7 text-center">{modalData.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
