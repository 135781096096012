import React from "react";

const MobileCardComponent = ({ data, updateModalData, setShow }) => {
  const handleModal = (data) => {
    // console.log("data from mobile-card",data);
    updateModalData(data)
    setShow(true)
  }

  return (
    <>
      <div className="bg-white rounded-t-md shadow-md rounded-b-md">
        <div className="mx-auto flex justify-between p-6">
          <div>
            <div className="font-bold text-primary font-['Lato'] text-[15px] mb-3">
              {data.name}
            </div>
            <p className="text-slate-900 text-sm text-[14px]">
              {data.position}
            </p>
          </div>
          <div className="shrink-0">
            <img
              className="h-12 w-12 border-2 border-primary rounded-full"
              src={data.img}
            />
          </div>
        </div>
        <div
          className="bg-lightGreen p-3 lg:p-4 md:p-4 rounded-b-md  uppercase font-bold text-[13px]"
          onClick={() => handleModal(data)}
        >
          Click to Read Bio
        </div>
      </div>
    </>
  );
};

export default MobileCardComponent;
