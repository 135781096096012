import Kritika from '../../assets/img/teams/kritika.jpeg'
import Vishal from "../../assets/img/teams/vishal.jpeg";
import Akash from "../../assets/img/teams/akash.jpeg";
import Joy from '../../assets/img/teams/joy.jpeg'
import Rathor from "../../assets/img/teams/rathor.jpeg";
import Vidisha from '../../assets/img/teams/vidisha.jpeg'
import Saurav from '../../assets/img/teams/saurav.jpeg'
import Amir from '../../assets/img/teams/amir.jpeg'

export const coreTeam = [
  {
    name: "Amir Sohel",
    position: "CEO / Tech Enginner",
    img: Amir,
    desc: "Amir is founder of Fusionlite, with 7 years of experience in IT Industry. He worked on many technical domains. He helped various startups and commercial enterprises like Starbucks and Nike. Building, Managing, and Delivering applications are his expertise.",
  },
  {
    name: "Kritika Kelawat",
    position: "Busniess Manager",
    img: Kritika,
    desc: "Kritika has been working as an IT Professional for the last five years. She has collaborated with several clients from diverse regions. She has delivered numerous complex functionalities for the clients under her consultation.",
  },
  {
    name: "Akash Raj",
    position: "Full Stack Enginner",
    img: Akash,
    desc: "Akash is full stack developer with 3 years of experience. He is having experience in many fields but not limited to just web. He hold a degree in CS engineering. Hold good record of delivering solutions to complex problems.",
  },
  {
    name: "Saurav Sharma",
    position: "Full Stack Enginner",
    img: Saurav,
    desc: "With 3+ years of web development experience under his belt, Saurav is a forward thinking individual. Keeping up-to-date on the latest tech trends and introducing better and optimized technologies to his projects is how he likes to get work done.",
  },
];

export const DevTeam = [
  {
    name: "Vishal Rathor",
    position: "React Developer",
    img: Rathor,
    desc: "A Full Stack Developer with 2 years of experience. He Worked as a Freelancer with high level of customer satisfaction record and worked in different domains such as Ecommerce, Tours and Telecom. He has rich hands-on experience with JavaScript & React",
  },
  {
    name: "Vidisha Vaid",
    position: "Software Engineer",
    img: Vidisha,
    desc: "Vidisha is Software Developer, Hold 4 years of experience in Frontend development. She worked on Anuglar, React and other Frontend Framework and Library. Aside from JavaScript, she also has experience with .net and other MVC Frameworks.",
  },
  {
    name: "Joy Suther",
    position: "Graphic / Product Designer",
    img: Joy,
    desc: "Joy is a Product Designer, he has 5+ years of experience in UX design and having knowledge of uncountable tools like Wireframing, UX flow, UI Design, Style guide creation, Product Branding, Figma , Photoshop , Illustrator, After Effects.",
  },

  {
    name: "Vishal Hasnani",
    position: "Full Stack Enginner",
    img: Vishal,
    desc: "Vishal is a proactive, result-oriented, and technically sound person. As a full-stack developer carrying around 6 years of experience to craft ideas for the real world by using the latest technologies stack and best practices.",
  },
];