import React from "react";
import { Link } from "react-scroll";
import "./index.css";
import Brnadicon from "../../assets/img/home/brand_icon.svg";
import Arrowicon from "../../assets/img/home/arrow.svg";
import MouseIcon from "../../assets/img/home/mouse.svg";

const BannerComponent = () => {
  return (
    <section className=" ban" id="home">
      <div className="seprate bg-primary">
        <div className=" pl-4 pr-4 pt-24 md:pl-24	md:pt-28">
          <h3
            className=" uppercase leading-[54px] md:leading-[182px]"
            data-aos="fade-up"
          >
            {" "}
            <span className="text-secondary text-web  flex ">
              Web{" "}
              <img
                className="inline-block ml-2 lg:ml-4 w-6 md:w-12 xl:w-[4rem]"
                src={Brnadicon}
              />
            </span>
            <span className="text-head studio_font relative">
              Studio{" "}
              <img
                className="absolute top-[1px] md:top-[-5px] img_pad w-[0.60rem]  xl:w-7 animate-ping"
                src={Arrowicon}
              />
            </span>
          </h3>
          <p
            className="text-white xs:text-[14px] lg:text-[16px] md:text-[16px] lg:w-3/4"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            We are a platform where technology and innovation meet to bring
            people and businesses together for a prosperous tomorrow.
          </p>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <button className="bg-secondary px-3 py-1 md:pr-7 md:pl-7 md:pt-3 md:pb-3 f  rounded-sm mt-6 font-bold text-primary">
              JOIN US
            </button>
          </Link>
          <div className="text-right w-4/5">
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <img
                className="hidden xl:flex inline-block animate-bounce right-0 left-0 cursor-pointer absolute bottom-[40px] m-auto"
                src={MouseIcon}
                alt="scroll"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="seprate_image	banner_image bg-cover	bg-center pr-24	text-right">
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          <button className="hidden xl:flex float-right bg-primary pr-7 pl-7 pt-3 pb-3 rounded mt-6 font-bold text-white">
            CONTACT US
          </button>
        </Link>
      </div>
    </section>
  );
};

export default BannerComponent;
