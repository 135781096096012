import React from "react";
import Image from "../../assets/img/teams/saurav.jpeg"
import SmallQuot from "../../assets/img/people/small-quotation.svg"
import SmallQuotWhite from "../../assets/img/people/small-quotation-white.svg"

const TestimonialCard = ({ data }) => {
  return (
    <div className="rounded-3xl shadow-xl bg-white" data-aos="zoom-in">
      <div className="mx-auto p-6 mb-4  txt">{data.description}</div>
      <div
        className={`${data.bgColour} px-6 pt-6 pb-2 md:pb-6 rounded-b-3xl flex justify-between`}
      >
        <div className="flex ">
          <img
            className={` w-16 h-16  md:h-24 md:w-24 border-4 ${data.bColor} rounded-full -mt-12`}
            src={data.image}
          />
          <div className="ml-3">
            <div
              className={`${
                data.bgColour === "bg-primary" ? "text-white" : "text-primary"
              } font-bold  font-['Lato'] text-md md:text-2xl mb-1 md:mb-3 whitespace-nowrap`}
            >
              {data.name}
            </div>
            <p
              className={`${
                data.bgColour === "bg-primary" ? "text-white" : "text-primary"
              }`}
            >
              {data.designation}
            </p>
          </div>
        </div>
        <img
          className="h-4 md:h-8 mt-1"
          src={data.cardColour === "primary" ? SmallQuotWhite : SmallQuot}
        />
      </div>
    </div>
  );
};

export default TestimonialCard;
